import { apiService } from "../../services/api.services";
import { agentConst } from "../constants";
import { userActions } from "./auth.action";
import { statusActions } from "./status.action";

const setCount = (type, count) => ({
    type,
    count
});

const setAgent = (agent) => ({
    type: agentConst.SET_AGENT,
    agent
});

const setMasterAgent = (masterAgent) => ({
    type: agentConst.SET_MASTER_AGENT,
    masterAgent
});

const setPlayer = (player) => ({
    type: agentConst.SET_PLAYER,
    player
});

const setAllAgents = (agents) => ({
    type: agentConst.SET_ALL_AGENTS,
    agents
});

const setAllMasterAgents = (masterAgents) => ({
    type: agentConst.SET_ALL_MASTER_AGENTS,
    masterAgents
});

const setAllPlayers = (players) => ({
    type: agentConst.SET_ALL_PLAYERS,
    players
});

const addNewAgent = (agent) => ({
    type: agentConst.CREATE_AGENT,
    agent
});

const addNewMasterAgent = (masterAgent) => ({
    type: agentConst.CREATE_MASTER_AGENT,
    masterAgent
});

const addNewPlayer = (player) => ({
    type: agentConst.CREATE_PLAYER,
    player
});

const updateAgent = (agent) => ({
    type: agentConst.UPDATE_AGENT,
    agent
});

const updatePlayer = (player) => ({
    type: agentConst.UPDATE_PLAYER,
    player
});

const updateMasterAgent = (agent) => ({
    type: agentConst.UPDATE_MASTER_AGENT,
    agent
});

const setUnitHistory = (type, unitHistory, count) => ({
    type: (type=="topup")? agentConst.SET_UNIT_TOPUP_HISTORY: agentConst.SET_UNIT_WITHDRAW_HISTORY,
    unitHistory,
    count
});

const setAnnouncement = (announcement) => ({
    type: agentConst.SET_ANNOUNCEMENT,
    announcement
});

const set2dGrossProfit = (grossProfit, count) => ({
    type: agentConst.SET_2D_GROSS_PROFIT,
    grossProfit,
    count,
});

const set3dGrossProfit = (grossProfit, count) => ({
    type: agentConst.SET_3D_GROSS_PROFIT,
    grossProfit,
    count,
});

const set2dBetReport = (betReport, count) => ({
    type: agentConst.SET_2D_BET_REPORT,
    betReport,
    count,
});

const set3dBetReport = (betReport, count) => ({
    type: agentConst.SET_3D_BET_REPORT,
    betReport,
    count,
});

const set2dBetReportDetail = (betReportDetail, count) => ({
    type: agentConst.SET_2D_BET_REPORT_DETAIL,
    betReportDetail,
    count,
});

const set3dBetReportDetail = (betReportDetail, count) => ({
    type: agentConst.SET_3D_BET_REPORT_DETAIL,
    betReportDetail,
    count,
});

const getCounts = () => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("get", "agent/get-counts");
            const { agentCount, masterAgentCount } = response;
            dispatch(setCount(agentConst.SET_ALL_AGENT_COUNT, agentCount));
            dispatch(setCount(agentConst.SET_ALL_MASTER_AGENT_COUNT, masterAgentCount));
        } catch(err) {
            dispatch(setCount(agentConst.SET_ALL_AGENT_COUNT, 0));
            dispatch(setCount(agentConst.SET_ALL_MASTER_AGENT_COUNT, 0));

            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const getUnitHistory = ({channel, type, page=1, self=false, limit, startDate, endDate, keyword}) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("get", "agent/get-tranx-history?channel="+channel+"&type="+type+"&page="+page+"&startDate="+startDate+"&endDate="+endDate+"&limit="+limit+"&self="+self+"&keyword="+keyword);
            const { count, unitTransactions } = response;
            dispatch(setUnitHistory(type, unitTransactions.map((transaction) => ({
                ...transaction,
                totalamount: transaction.topupamount+0+transaction.commission,
                date: new Date(transaction.createdat).toLocaleDateString(),
                agenttype: transaction.agenttype? transaction.agenttype: "Player"
            })), count));
        } catch(err) {
            dispatch(setUnitHistory(type, [], 0));
            
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error)); 
        }
        dispatch(statusActions.setLoading());
    }
}

const getAllMasterAgents = ({page, startDate, endDate, keyword, order, orderBy}) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try{
            let orderKeyword = "";
            if (order && orderBy) {
                orderKeyword = "&orderBy="+orderBy+"&order="+order;
            }
            const response = await apiService.call("get", "agent/get-master-agents?page="+page+"&startDate="+startDate+"&endDate="+endDate+"&keyword="+keyword+orderKeyword);
            const { count, masterAgents } = response;
            dispatch(setCount(agentConst.SET_CURRENT_MASTER_AGENT_COUNT, count));
            dispatch(setAllMasterAgents(masterAgents.map((agent) => ({
                ...agent,
                status: agent.status?"Activated": "Deactivated",
                createdat: new Date(agent.createdat).toLocaleDateString(),
                updatedat: agent.updatedat?new Date(agent.updatedat).toLocaleDateString():"-",
            }))));
        } catch(err) {
            dispatch(setCount(agentConst.SET_CURRENT_MASTER_AGENT_COUNT, 0));
            dispatch(setAllMasterAgents([]));

            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const getAllAgents = ({page, startDate, endDate, keyword, order, orderBy}) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try{
            let orderKeyword = "";
            if (order && orderBy) {
                orderKeyword = "&orderBy="+orderBy+"&order="+order;
            }
            const response = await apiService.call("get", "agent/get-agents?page="+page+"&startDate="+startDate+"&endDate="+endDate+"&keyword="+keyword+orderKeyword);
            const { count, agents } = response;
            dispatch(setCount(agentConst.SET_CURRENT_AGENT_COUNT, count));
            dispatch(setAllAgents(agents.map((agent) => ({
                ...agent, 
                status: agent.status?"Activated": "Deactivated",
                createdat: new Date(agent.createdat).toLocaleDateString(),
                updatedat: agent.updatedat?new Date(agent.updatedat).toLocaleDateString():"-",
            }))));
        } catch(err) {
            dispatch(setCount(agentConst.SET_CURRENT_AGENT_COUNT, 0));
            dispatch(setAllAgents([]));
            
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const getAgentDetail = (agentid) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call('get', 'agent/get-agent-detail?agentid='+agentid);
            const {agent} = response;
            dispatch(updateMasterAgent({...agent, status:  agent.status?"Activated": "Deactivated"}));
            dispatch(setMasterAgent({
                ...agent, 
                status:  agent.status?"Activated": "Deactivated",
                createdat: agent.createdat?new Date(agent.createdat).toLocaleDateString():"-",
                updatedat: agent.updatedat?new Date(agent.updatedat).toLocaleDateString():"-",
            }));
            dispatch(updateAgent({...agent, status:  agent.status?"Activated": "Deactivated"}));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const createAgent = (data) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("post", "agent/new", data);
            const {agent} = response;
            dispatch(addNewAgent(agent));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const createPlayer = (data) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("post", "player/new", data);
            const {player} = response;
            dispatch(addNewPlayer(player));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const toggleActivation = (id) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("get", "agent/toggle-activate?agentid="+id);
            const {agent} = response;
            dispatch(updateMasterAgent({...agent, status:  agent.status?"Activated": "Deactivated"}));
            dispatch(updateAgent({...agent, status:  agent.status?"Activated": "Deactivated"}));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const updateMasterAgentApi = (data) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("put", "agent/update", data);
            const {agent} = response;
            dispatch(updateMasterAgent({...agent, status: agent.status?"Activated": "Deactivated"}));
            dispatch(updateAgent({...agent, status: agent.status?"Activated": "Deactivated"}));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const updateAgentApi = (data) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("put", "agent/update", data);
            const {agent, auth} = response;
            dispatch(updateMasterAgent({...agent, status: agent.status?"Activated": "Deactivated"}));
            dispatch(updateAgent({...agent, status: agent.status?"Activated": "Deactivated"}));
            dispatch(userActions.updateAuth({...auth, status: auth.status?"Activated": "Deactivated"}))
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const updatePlayerData = (data) => {
    return async(dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("put", "player/update", data);
            const {player, auth} = response;
            dispatch(updatePlayer({...player, status: player.status?"Activated": "Deactivated"}));
            dispatch(userActions.updateAuth({...auth, status: auth.status?"Activated": "Deactivated"}))
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const getAllPlayers = ({page, startDate, endDate, keyword, order, orderBy}) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try{
            let orderKeyword = "";
            if (order && orderBy) {
                orderKeyword = "&orderBy="+orderBy+"&order="+order;
            }
            const response = await apiService.call("get", "agent/get-players?page="+page+"&startDate="+startDate+"&endDate="+endDate+"&keyword="+keyword+orderKeyword);
            const { count, players } = response;
            dispatch(setCount(agentConst.SET_CURRENT_PLAYER_COUNT, count));
            dispatch(setAllPlayers(players.map((player) => ({
                ...player, 
                status: player.status?"Activated": "Deactivated",
                createdat: new Date(player.createdat).toLocaleDateString(),
                updatedat: new Date(player.updatedat).toLocaleDateString(),
            }))));
        } catch(err) {
            dispatch(setCount(agentConst.SET_CURRENT_PLAYER_COUNT, 0));
            dispatch(setAllPlayers([]));
            
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const getPlayerDetail = (playerid) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call('get', 'agent/get-player-detail?playerid='+playerid);
            const {player} = response;
            // dispatch(updateMasterAgent({...player, status:  player.status?"Activated": "Deactivated"}));
            dispatch(setPlayer({...player, status:  player.status?"Activated": "Deactivated"}));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const getAnnouncement = () => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const {announcement} = await apiService.call("get", "agent/get-announcement");
            dispatch(setAnnouncement(announcement));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const updateAnnouncement = (data) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const {announcement} = await apiService.call("post", "agent/set-announcement", data);
            dispatch(setAnnouncement(announcement));
            dispatch(statusActions.setSuccess(true));
            dispatch(statusActions.setMessage("Updated successfully"));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const updateContact = (data) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const {announcement} = await apiService.call("post", "agent/set-phonenumber", data);
            dispatch(setAnnouncement(announcement));
            dispatch(statusActions.setSuccess(true));
            dispatch(statusActions.setMessage("Updated successfully"));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const get2dGrossProfitData = ({page, startDate, endDate, round="All"}) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const { grossprofit, count } = await apiService.call("get", "agent/2d-gross-profit?page="+page+"&startDate="+startDate+"&endDate="+endDate+"&round="+round+"&limit=10");
            dispatch(set2dGrossProfit(grossprofit.map(num => ({
                ...num,
                date: new Date(num.createdat).toLocaleDateString('en-US', {timeZone: "Asia/Yangon"}),
            })), count));
            dispatch(statusActions.setSuccess(true));
            dispatch(statusActions.setMessage("Fetch data successfully"));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const get3dGrossProfitData = ({page, startDate, endDate}) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const { grossprofit, count } = await apiService.call("get", "agent/3d-gross-profit?page="+page+"&startDate="+startDate+"&endDate="+endDate+"&limit=10");
            dispatch(set3dGrossProfit(grossprofit.map(num => ({
                ...num,
                date: new Date(num.createdat).toLocaleString('en-US', {timeZone: "Asia/Yangon"}),
            })), count));
            dispatch(statusActions.setSuccess(true));
            dispatch(statusActions.setMessage("Fetch data successfully"));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const changePassword = (data) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("put", "agent/changepassword", data);
            const {success} = response;
            if (success) {
                dispatch(statusActions.setSuccess(true));
                dispatch(statusActions.setMessage("Changed password successfully"));
            }
        } catch(err) {
            const error = err?.response?.data?.message ?? "Invalid Password";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const get2dBetReportData = ({page, startDate, endDate, round="All"}) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const { data, count } = await apiService.call("get", "agent/2d-bet-report?page="+page+"&startDate="+startDate+"&endDate="+endDate+"&round="+round+"&limit=10");
            dispatch(set2dBetReport(data.map(num => ({
                ...num,
                date: new Date(num.createdat).toLocaleDateString('en-US', {timeZone: "Asia/Yangon"}),
                roundstatus: num.status === 1 ? "Finished": "Pending",
            })), count));
            dispatch(statusActions.setSuccess(true));
            dispatch(statusActions.setMessage("Fetch data successfully"));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const get3dBetReportData = ({page, startDate, endDate}) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const { data, count } = await apiService.call("get", "agent/3d-bet-report?page="+page+"&startDate="+startDate+"&endDate="+endDate+"&limit=10");
            dispatch(set3dBetReport(data.map(num => ({
                ...num,
                date: new Date(num.createdat).toLocaleString('en-US', {timeZone: "Asia/Yangon"}),
            })), count));
            dispatch(statusActions.setSuccess(true));
            dispatch(statusActions.setMessage("Fetch data successfully"));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const get2dBetReportDetail = ({page, roundid, playerid}) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const { data, count } = await apiService.call("get", "agent/2d-bet-report-detail?page="+page+"&roundid="+roundid+"&playerid="+playerid);
            dispatch(set2dBetReportDetail(data.map(num => ({
                ...num,
                date: new Date(num.createdat).toLocaleString('en-US', {timeZone: "Asia/Yangon"}),
                slipStatus: num.status === 2 ? "Pending": num.status === 1? "Win": "Lose",
            })), count));
            dispatch(statusActions.setSuccess(true));
            dispatch(statusActions.setMessage("Fetch data successfully"));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

const get3dBetReportDetail = ({page, roundid, playerid}) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const { data, count } = await apiService.call("get", "agent/3d-bet-report-detail?page="+page+"&roundid="+roundid+"&playerid="+playerid);
            dispatch(set3dBetReportDetail(data.map(num => ({
                ...num,
                date: new Date(num.createdat).toLocaleString('en-US', {timeZone: "Asia/Yangon"}),
                slipStatus: num.status === 2 ? "Pending": num.status === 1? "Win": "Lose",
            })), count));
            dispatch(statusActions.setSuccess(true));
            dispatch(statusActions.setMessage("Fetch data successfully"));
        } catch(err) {
            const error = err?.response?.data?.message ?? "Some errors occurred";
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(error));
        }
        dispatch(statusActions.setLoading());
    }
}

export const agentActions = {
    getCounts,
    getAllMasterAgents,
    getAllAgents,
    getAgentDetail,
    createAgent,
    createPlayer,
    toggleActivation,
    updateMasterAgentApi,
    getUnitHistory,
    getAllPlayers,
    getPlayerDetail,
    updatePlayerData,
    updateAgentApi,
    getAnnouncement,
    updateAnnouncement,
    updateContact,
    get2dGrossProfitData,
    get3dGrossProfitData,
    changePassword,
    get2dBetReportData,
    get3dBetReportData,
    get2dBetReportDetail,
    get3dBetReportDetail,
}