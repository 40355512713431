import axios from 'axios';
import { deleteAgent } from '../utils/agent';
import { deleteToken, getToken } from '../utils/token';

// const host = "http://localhost:3005/api/";
// const host = "https://stagingapi.billionaire2d3d.edkamm.com/api/";
const host = "https://api.billionaire2d3d.edkamm.com/api/"

const token = getToken();

axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const call = async(method, path, data) => {
    try {
        const res = await axios[method](host+path, data);
        return res.data;
    } catch (err) {
        console.log(err.response);
        if (err?.response?.status === 401) {
            deleteToken();
            deleteAgent();
            setAuthHeader(null);
        }
        throw err;
    }
}

const setAuthHeader = token => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}


export const apiService = {
    call,
    setAuthHeader
}