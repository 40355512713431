import { Box, Button, Card, CardContent, Grid, Menu, Tab, Tabs, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonSelectInput from "../../components/ButtonSelectInput";
import MyDateRangePicker from "../../components/MyDateRangePicker";
import { agentActions, numberActions } from "../../store/actions";
import { formatEndDateForApi, formatStartDateForApi } from "../../utils/misc";
import Layout from "../Layout";

const total2dNumbers = 100;
const perRow2dNumbers = 5;

const total3dNumbers = 100;
const perRow3dNumbers = 5;

const prefix3d = 10;

export default function NumberReport() {

    const [tabValue, setTabValue] = useState("2d");

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const [selectedPrefix, setSelectedPrefix] = useState(null);

    const handleChangePrefix = (prefix) => {
        setSelectedPrefix(prefix)
    }

    const dispatch = useDispatch();

    const {betNumbers2d, betNumbers3d, total2DBetAmount, totalWonAmount2d, total3DBetAmount, totalWonAmount3d} = useSelector(state => state.numberManagement);

    const created2dData=[];
    let perRow2d = [];
    // let total2DBetAmount = 0;
    // let total2DBetAmount = 0;
    
    for(let i=0; i<total2dNumbers; i++) {
        if (i!==0 && i%perRow2dNumbers===0) {
            created2dData.push(<Grid key={i/perRow2dNumbers} container sx={{ mt: "1rem" }} spacing={2}>
                {perRow2d}
            </Grid>);
            perRow2d =[]
        }
        let number = ((i<10)?"0":"")+i;
        let betNumber = betNumbers2d.find(bet => bet.number === number);
        // total2DBetAmount += betNumber? betNumber.amount: 0;
        perRow2d.push(<Grid key={i} item sm={2.4}>
            <Card sx={{backgroundColor: "transparent", border: "none"}}>
                <CardContent sx={{backgroundColor: "rgba(241, 241, 241, 1)"}}>
                    <Typography sx={{ fontSize: "1.5rem", textAlign: "center"}} color="secondary">{number}</Typography>
                    <Typography sx={{ mt: "0.5rem", textAlign: "center" }}>{betNumber? betNumber.amount: 0}</Typography>
                </CardContent>
            </Card>
        </Grid>);
        if (i===total2dNumbers-1) {
            created2dData.push(<Grid key={i/perRow2dNumbers} container sx={{ mt: "1rem" }} spacing={2}>
                {perRow2d}
            </Grid>);
            perRow2d =[]
        }
    }

    const prefix3dData = [];
    for (let i=0; i<prefix3d; i++) {
        prefix3dData.push(<Grid item key={i}>
            <Card onClick={() => handleChangePrefix(i)} sx={{backgroundColor: "transparent", border: "none", cursor: "pointer"}}>
                <CardContent sx={{backgroundColor: (selectedPrefix===i)?"#7A0025":"rgba(241, 241, 241, 1)", padding: "10px !important"}}>
                    <Typography sx={{ color: (selectedPrefix===i)? "#fff": "#000", fontSize: "1rem", lineHeight: "1rem", textAlign: "center" }}>{i}</Typography>
                </CardContent>
            </Card>
        </Grid>);
    }

    const created3dData=[];
    let perRow3d = [];
    // let total3DBetAmount = 0;

    for(let i=0; i<total3dNumbers; i++) {
        if (i!==0 && i%perRow3dNumbers===0) {
            created3dData.push(<Grid key={i/perRow3dNumbers} container sx={{ mt: "1rem" }} spacing={2}>
                {perRow3d}
            </Grid>);
            perRow3d =[]
        }
        let number = selectedPrefix+(((i<10)?"0":"")+i);
        let betNumber = betNumbers3d.find(bet => bet.number === number);
        // total3DBetAmount += betNumber? betNumber.amount: 0;
        perRow3d.push(<Grid key={i} item sm={2.4}>
            <Card sx={{backgroundColor: "transparent", border: "none"}}>
                <CardContent sx={{backgroundColor: "rgba(241, 241, 241, 1)"}}>
                    <Typography sx={{ fontSize: "1.5rem", textAlign: "center"}} color="secondary">{number}</Typography>
                    <Typography sx={{ mt: "0.5rem", textAlign: "center" }}>{betNumber? betNumber.amount: 0}</Typography>
                </CardContent>
            </Card>
        </Grid>);
        if (i===total3dNumbers-1) {
            created3dData.push(<Grid key={i/perRow3dNumbers} container sx={{ mt: "1rem" }} spacing={2}>
                {perRow3d}
            </Grid>);
            perRow3d =[]
        }
    }

    const [datepickerAnchorEl, setDatePickerAnchorEl] = useState(null);
    const open = Boolean(datepickerAnchorEl);
    const [value, setValue] = useState({});

    const toggleDatePicker = (e) => {
        if (datepickerAnchorEl !== null) {
            setDatePickerAnchorEl(null);
        } else {
            setDatePickerAnchorEl(e.currentTarget)
        }
    }

    const rounds = ["All", "AM", "PM"];

    const [selectedRound, setSelectedRound] = useState(rounds[0]);

    const fetchData = useCallback(() => {
        if (value.startDate && value.endDate) {
            console.log("Selected round: ", selectedRound);
            dispatch(numberActions.get2dBetNumbers(formatStartDateForApi(value?.startDate), formatEndDateForApi(value?.endDate), selectedRound));
            dispatch(numberActions.get3dBetNumbers(formatStartDateForApi(value?.startDate), formatEndDateForApi(value?.endDate)));
        } else {
            dispatch(numberActions.get2dBetNumbers());
            dispatch(numberActions.get3dBetNumbers());
        }
    }, [value, selectedRound, dispatch]);

    useEffect(() => {
        fetchData();
    }, [selectedRound, value, fetchData])

    const handleSelectedRoundChange = (e, value) => {
        setSelectedRound(e.target.value);
    }

    const percentageCalc = () => {
        const total = (tabValue == '2d')? total2DBetAmount: total3DBetAmount;
        const won = (tabValue == '2d')? totalWonAmount2d: totalWonAmount3d;

        let val = 0;
        if (total == 0 || total == won) {
            val = 0;
        }
        else if (total > won) {
            val = ((total-won)/total) * 100;
        } else {
            val = ((won-total)/total) * 100;
        }

        return Number.isInteger(val) ? val: val.toFixed(2);
    }

    const colorCalc = () => {
        const total = (tabValue == '2d')? total2DBetAmount: total3DBetAmount;
        const won = (tabValue == '2d')? totalWonAmount2d: totalWonAmount3d;

        let val = 0;
        if (total == won) {
           return {};
        }
        else if (total > won) {
            return { color: 'green' };
        } else {
            return { color: 'red' };
        }
    }

    return (
        <Layout>
            <Grid container spacing={2} sx={{ alignItems: "center", mb: '1rem' }}>
                <Grid item
                sx={{ flexGrow: 1 }}
                >
                    <Typography variant="h5" sx={{ fontWeight: "500" }}>Number Report</Typography>
                </Grid>
                {
                    (tabValue==="2d" && value.startDate && value.endDate) && (
                        <Grid item>
                            <ButtonSelectInput selected={selectedRound} items={rounds} onChange={handleSelectedRoundChange} />
                        </Grid>
                    )
                }
                <Grid item>
                    <Button onClick={toggleDatePicker} variant="contained" color="primary">Filter by date</Button>
                </Grid>
            </Grid>
            <Menu
                anchorEl={datepickerAnchorEl}
                open={open}
                // onClose={toggleDatePicker}
                sx={{
                    mt: "10px",
                    "& .MuiPaper-root": {
                        minWidth: "0px",
                        minHeight: "0px"
                    },
                    "& ul": {
                        padding: 0,
                    }
                }}
            >
                <MyDateRangePicker open={open} toggle={toggleDatePicker} value={value} setValue={setValue} />
            </Menu>
            {(value.startDate && value.endDate) && (
                <Grid container spacing={2} sx={{ alignItems: "center", mb: '1rem' }}>
                <Grid item
                sx={{ flexGrow: 1 }}
                >
                    <Typography>{"From "+new Date(value?.startDate).toLocaleDateString() +" To "+new Date(value?.endDate).toLocaleDateString()}</Typography>
                </Grid>
                <Grid item>
                    <Button onClick={() => setValue({})} variant="contained" color="primary">Clear</Button>
                </Grid>
            </Grid>
            )}
            
            <Grid
            container
            direction="row"
            spacing={4}
            sx={{
                height: '100%'
            }}
            >
                <Grid
                sm={8}
                item
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    pr: '2rem',
                }}
                >
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label="2D Total Number" value="2d" id="2d" sx={{ mr: "2rem" }} />
                        <Tab label="3D Total Number" value="3d" id="3d" />
                    </Tabs>
                    <TabPanel value={tabValue} index="2d">
                        {created2dData}
                    </TabPanel>
                    <TabPanel value={tabValue} index="3d">
                        <Grid container spacing={2} sx={{ mt: "1rem" }}>
                            {prefix3dData}
                        </Grid>
                        {(selectedPrefix!==null) && created3dData}
                    </TabPanel>
                </Grid>
                <Grid
                item
                sm={4}
                >
                    <Typography variant='h6'>Total Bet Amount : {tabValue==='2d'? total2DBetAmount: total3DBetAmount}</Typography>
                    <Typography variant='h6'>Total Won Amount : {tabValue==='2d'? totalWonAmount2d: totalWonAmount3d}</Typography>
                    <Typography variant='h6'>Profit/Loss Percentage : <span style={colorCalc()}>{percentageCalc()}%</span></Typography>
                </Grid>
            </Grid>

        </Layout>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
}